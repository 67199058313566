import {
  documents,
  time,
  person,
  clipboard,
  car,
  copy,
  ticket,
  wallet,
  card,
  people,
  book,
  informationCircle,
  documentText,
  listCircle,
  hourglass,
  newspaper,
  calendar,
  cube,
  home,
  list,
  barcode,
  shareSocial,
} from "ionicons/icons";

//Chiave localsotrage
const LOCALSTORAGE_KEY = `user${process.env.VUE_APP_LOCALSTORAGE_KEY}`;

// Pagina da vedere dopo il login
const pageAfterLogin = "presenze";

// Pulsanti presenti nella dashboard
const dashbordButtons = {
  presenze: true,
  documenti: true,
  richieste: true,
  automezzi: true,
  spese: true,
  tickets: true,
};

// Configurazione del menu
const config_menu = [
  {
      show: true,
      route: "presenze",
      icon: time,
      name: "Presenze",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: true,
      route: "documenti",
      icon: documents,
      name: "Documenti",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: true,
      route: "richieste",
      icon: clipboard,
      name: "Richieste",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: true,
      route: "banca",
      icon: hourglass,
      name: "Banca ore",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: true,
      route: "automezzi",
      icon: car,
      name: "Automezzi",
      allowedRoles: ['Admin']
  },
  {
      show: true,
      route: "spese",
      icon: wallet,
      name: "Note spesa",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: false,
      route: "calendario",
      icon: calendar,
      name: "Calendario",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: false,
      route: "rimborsi",
      icon: card,
      name: "Rimborsi km",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: false,
      route: "clienti",
      icon: people,
      name: "Anagrafiche",
      allowedRoles: ['Admin']
  },
  {
      show: false,
      route: "progetti",
      icon: listCircle,
      name: "Progetti",
      allowedRoles: ['Admin']
  },
  {
      show: false,
      route: "leads",
      icon: book,
      name: "Leads",
      allowedRoles: ['Admin']
  },
  {
      show: false,
      route: "interventi",
      icon: documentText,
      name: "Interventi",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: false,
      route: "rapportini",
      icon: documentText,
      name: "Rapportini",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: false,
      route: "tickets",
      icon: ticket,
      name: "Tickets",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: false,
      route: "task",
      icon: home,
      name: "Tasks",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: false,
      route: "timesheet",
      icon: time,
      name: "Timesheet",
      allowedRoles: ['Admin']
  },
  {
      show: false,
      route: "bacheca",
      icon: shareSocial,
      name: "Bacheca",
      allowedRoles: ['Admin']
  },
  {
      show: false,
      route: "programma",
      icon: calendar,
      name: "Programma lavoro",
      allowedRoles: ['Admin', 'Employee']
  },
  {
      show: false,
      route: "ordini",
      icon: cube,
      name: "Ordini interni",
      allowedRoles: ['Admin']
  },
  {
      show: false,
      route: "sondaggi",
      icon: newspaper,
      name: "Sondaggi",
      allowedRoles: ['Admin']
  },
  {
      show: false,
      route: "magazzino",
      icon: barcode,
      name: "Magazzino",
      allowedRoles: ['Admin']
  },
  {
      show: false,
      route: "todo",
      icon: list,
      name: "Liste",
      allowedRoles: ['Admin', 'Employee']
  },
  /* {
      show: true,
      route: "testbarcode",
      icon: list,
      name: "TestBarcode",
      allowedRoles: ['Admin', 'Employee']
  }, */
  {
      show: true,
      route: "profilo",
      icon: person,
      name: "Profilo",
      allowedRoles: ['Admin', 'Employee']
  },
];

/**
* 
* RAPPORTINI
* 
*/
// Configurazione operatori e prodotti 
const showOperatori = true;
const showProdotti = true;

// Configurazione dei permessi per ruolo
const rolePermissions = {
  'Admin': ['*'], // '*' indica accesso a tutte le rotte
  'Employee': ['*'], // '*' indica accesso a tutte le rotte
  'Customer': ['*'], // '*' indica accesso a tutte le rotte
  //'Employee': ['login', 'presenze', 'documenti', 'richieste', 'banca ore', 'automezzi', 'Nota Spese', 'rimborsi km', 'interventi', 'tickets', 'tasks', 'programma lavoro', 'profilo'],
  //'Sales Agent': ['login', 'presenze', 'documenti', 'richieste', 'automezzi', 'spese', 'calendario', 'clienti', 'progetti', 'leads', 'profilo'],
};

export { LOCALSTORAGE_KEY, pageAfterLogin, dashbordButtons, config_menu, showProdotti, showOperatori, rolePermissions };
