import { createRouter, createWebHistory } from "@ionic/vue-router";
//import { RouteRecordRaw } from "vue-router";
import { RouteRecordRaw, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { openToast } from "@/services/toast";

import { LOCALSTORAGE_KEY, pageAfterLogin, rolePermissions } from "@/custom_config.js";
import { logOut } from "@/services/logout";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/Login.vue"),
        beforeEnter: (to, from, next) => {
            const user = localStorage.getItem(`${LOCALSTORAGE_KEY}`);
            const ricordami = localStorage.getItem("ricordami");
            if (user && ricordami) {
                next({ path: `/${pageAfterLogin}` });
            } else {
                next();
            }
        },
    },
    {
        path: "",
        redirect: `/${pageAfterLogin}`,
    },
    {
        //Presenze
        path: "/presenze",
        name: "Presenze",
        component: () => import("@/views/Presenze.vue"),
        meta: { requiresAuth: true , roles: ["Admin", "Employee"]},
    },
    {
        //Documenti
        path: "/documenti",
        name: "Documenti",
        component: () => import("@/views/Documenti.vue"),
        meta: { requiresAuth: true , roles: ["Admin", "Employee"]},
    },
    {
        //Richieste
        path: "/richieste",
        name: "Richieste",
        component: () => import("@/views/Richieste.vue"),
        meta: { requiresAuth: true , roles: ["Admin", "Employee"]},
    },
    {
        //BancaOre
        path: "/banca",
        name: "Banca ore",
        component: () => import("@/views/BancaOre.vue"),
        meta: { requiresAuth: true , roles: ["Admin", "Employee"]},
    },
    {
        //Automezzi
        path: "/automezzi",
        name: "Automezzi",
        component: () => import("@/views/Automezzi.vue"),
        meta: { requiresAuth: true , roles: ["Admin", "Employee"]},
    },
    {
        //Nota Spese
        path: "/spese",
        name: "Nota Spese",
        component: () => import("@/views/NotaSpese.vue"),
        meta: { requiresAuth: true , roles: ["Admin", "Employee"]},
    },
    {
        //Calendario
        path: "/calendario",
        name: "Calendario",
        component: () => import("@/views/Calendario.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Rimborsi km
        path: "/rimborsi",
        name: "Rimborsi km",
        component: () => import("@/views/Rimborsi.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Interventi
        path: "/interventi",
        name: "Interventi",
        component: () => import("@/views/Interventi.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Interventi
        path: "/rapportini",
        name: "Rapportini",
        component: () => import("@/views/Rapportini.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Tickets
        path: "/tickets",
        name: "Tickets",
        component: () => import("@/views/Tickets.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Timesheet
        path: "/timesheet",
        name: "Timesheet",
        component: () => import("@/views/Timesheet.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Clienti
        path: "/clienti",
        name: "Clienti",
        component: () => import("@/views/Clienti.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Progetti
        path: "/progetti",
        name: "Progetti",
        component: () => import("@/views/Progetti.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Leads
        path: "/leads",
        name: "Leads",
        component: () => import("@/views/Leads.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Bacheca
        path: "/bacheca",
        name: "SocialWall",
        component: () => import("@/views/SocialWall.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Sondaggi
        path: "/sondaggi",
        name: "Sondaggi",
        component: () => import("@/views/Sondaggi.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Confindustria
        path: "/conf",
        name: "Confindustria",
        component: () => import("@/views/Confindustria.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Programma Lavoro
        path: "/programma",
        name: "Programma Lavoro",
        component: () => import("@/views/ProgrammaLavoro.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Ordini
        path: "/ordini",
        name: "OrdiniInterni",
        component: () => import("@/views/OrdiniInterni.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Task
        path: "/task",
        name: "Tasks",
        component: () => import("@/views/Task.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Movimenti
        path: "/movimenti",
        name: "Movimenti",
        component: () => import("@/views/Movimenti.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //Magazzino
        path: "/magazzino",
        name: "Magazzino",
        component: () => import("@/views/Magazzino.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //ToDo List
        path: "/todo",
        name: "ToDoList",
        component: () => import("@/views/ToDoList.vue"),
        meta: { requiresAuth: true , roles: []},
    },
    {
        //simulatore
        path: "/simulatore",
        name: "Simulatore",
        component: () => import("@/views/Simulatore.vue"),
        meta: { requiresAuth: true , roles: ["Admin"]},
    },
    {
        //Cambio password
        path: "/changePassword",
        component: () => import("@/views/ChangePassword.vue"),
        meta: { requiresAuth: true , roles: ["Admin", "Employee"]},
    },
    {
        //Profilo
        path: "/profilo",
        name: "Profilo",
        component: () => import("@/views/Profilo.vue"),
        meta: { requiresAuth: true , roles: ["Admin", "Employee"]},
    }
];

/* routes.push({
    //Documenti
    path: "/documenti2",
    name: "Documenti2",
    component: () => import("@/views/Documenti.vue"),
    meta: { requiresAuth: true , roles: ["admin", "user"]},
}); */

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const userStr = localStorage.getItem(`${LOCALSTORAGE_KEY}`);
    const user = userStr ? JSON.parse(userStr) : null;

    if ((to.matched as RouteRecordRaw[]).some(record => record.meta.requiresAuth)) {
        if (!user) {
            next({ path: "/login" });
        } else {
            // Assumi che 'to.name' corrisponda al nome della rotta a cui l'utente sta cercando di accedere
            //const actionRequired = to.name.toLowerCase(); // Ottieni il nome della pagina/route
            const currentRoutName = typeof to.name === 'string' ? to.name.toLowerCase() : '';
            const userPermission = (rolePermissions[user.users_type_value] || []).map(role => role.toLowerCase());// Azioni permesse all'utente
            const routeRoles = (to.meta.roles as string[]) || []; // Ottieni i ruoli dalla meta

            const isAllowed = routeRoles.length > 0 && (userPermission.includes('*') ||  userPermission.includes(currentRoutName));

            /* console.log(currentRoutName);
            console.log(userPermission);
            console.log(isAllowed); */
            
           
            if (isAllowed) {
                next();
            } else {
                openToast("Non autorizzato ad accedere a questa pagina", "toast_danger");
                next(false); // Blocca completamente la navigazione
            }
        }
    } else {
        next();
    }
});

export default router;
